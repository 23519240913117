<template>
  <div class="edp-crowdChange bf-op">
    <section class="crowdChange-cate">
      <ul>
        <li
          v-for="(item, index) in cateList"
          :key="'crowdCate' + index"
          :class="{ selected: item.selected }"
          @click="changeCate(item.value)"
        >
          {{ item.name }}
        </li>
      </ul>

      <div class="crowdChange-type__control">
        <span class="crowdChange-type__control__btn" @click="showHideSet">{{
          $t("crowd.setSearch")
        }}</span>

        <span class="crowdChange-type__control__btn" @click="manageMyBag">{{
          $t("crowd.manageCrowdBag")
        }}</span>
      </div>
    </section>

    <section class="crowdChange-type">
      <!--<div class="crowdChange-type__iconContent">
        <span
          class="crowdChange-type__iconContent__left"
        ></span>
        <span
          class="crowdChange-type__iconContent__right"
        ></span>
      </div>-->

      <div
        class="crowdChange-type__articleContent"
        ref="articleContentOut"
        @mousedown="typeScrollDown"
      >
        <ul>
          <li
            v-for="(item, index) in typeList"
            :key="'crowdType' + index"
            :class="{ selected: item.selected }"
            v-show="item.packType == currentCate"
            @click="changeType(index)"
          >
            {{
              utils.formatLang($i18n.locale, item.packCnName, item.packEnName)
            }}
          </li>
        </ul>
      </div>
    </section>

    <edp-dialog
      class="crowd-manage-dialog"
      ref="crowdManageDialog"
      :title="$t('crowd.manageCrowdBag')"
    >
      <edpWaterBg percent="20%" opacity="0.6" slot="bgContent" />

      <div slot="content" class="crowd-manage-dialog__content">
        <ul class="crowd-manage-dialog__ul">
          <li style="width: 10%">{{ $t("crowd.manageBagTitle1") }}</li>
          <li style="width: 20%">{{ $t("crowd.manageBagTitle2") }}</li>
          <li style="width: 24%">{{ $t("crowd.manageBagTitle3") }}</li>
          <li style="width: 16%">{{ $t("crowd.manageBagTitle4") }}</li>
          <li style="width: 30%">{{ $t("crowd.manageBagTitle5") }}</li>
        </ul>

        <div class="crowd-manage-dialog__tableContent">
          <dl
            class="crowd-manage-dialog__dl"
            v-for="(item, index) in myBayListPage"
            :key="'crowdManageItem' + index"
          >
            <dd style="width: 10%">{{ index + 1 }}</dd>
            <dd style="width: 20%">
              {{
                utils.formatLang($i18n.locale, item.packCnName, item.packEnName)
              }}
            </dd>
            <dd style="width: 24%">
              {{
                item.packType == 1
                  ? $t("crowd.cateName_recommend")
                  : $t("crowd.cateName_normal")
              }}
            </dd>
            <dd style="width: 16%">
              <span v-if="item.packState == 0">{{ $t("crowd.ed_save") }}</span>
              <span v-if="item.packState == 1">{{
                $t("crowd.ed_enable")
              }}</span>
              <span v-if="item.packState == 2">{{
                $t("crowd.ed_disable")
              }}</span>
            </dd>
            <dd style="width: 30%">
              <div
                v-if="
                  (item.packType == 1 &&
                    userInfoMap.roles &&
                    userInfoMap.roles.includes('pack_admin')) ||
                  item.packType == 2
                "
              >
                <span
                  class="crowd-manage-dialog__control primary"
                  @click="editBag(item)"
                  >{{ $t("crowd.manageBag_eidt") }}</span
                >

                <span class="crowd-manage-dialog__sLine">|</span>

                <span
                  class="crowd-manage-dialog__control"
                  @click="enDisBag(item, index)"
                  >{{
                    item.packState == 1
                      ? $t("crowd.manageBag_disable")
                      : $t("crowd.manageBag_enable")
                  }}</span
                >

                <span class="crowd-manage-dialog__sLine">|</span>

                <span
                  class="crowd-manage-dialog__control"
                  @click="deleteBag(item, index)"
                  >{{ $t("crowd.manageBag_delete") }}</span
                >
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </edp-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "crowdChange",
  data() {
    return {
      currentCate: "",
      cateList: [
        {
          name: this.$t("crowd.cateName_recommend"),
          value: 1,
        },
        {
          name: this.$t("crowd.cateName_normal"),
          value: 2,
        },
      ],
      typeList: [],
      manageMyBagStatus: false,

      myBayListPage: [],

      nowBagIdList: [],
      nowSelectedBagId: "",

      crTypeWidth: 0,

      cateTypeWsBegin: 0,
      cateTypeWsEnd: 0,
      cateTypeWsDis: 0,
      cateTypeWsStatus: false,
    };
  },
  computed: {
    ...mapState({
      page_platform: (state) => state.login.page_platform,
      userInfoMap: (state) => state.login.userInfo,
    }),
  },
  methods: {
    ...mapActions({
      myBagList: "crowd/myBagList",
      defaultBagList: "crowd/defaultBagList",
      updateMyBag: "crowd/updateMyBag",
      deleteMyBag: "crowd/deleteMyBag",
    }),
    typeScrollDown(e) {
      if (this.page_platform === "windows") {
        e.preventDefault();
        this.cateTypeWsStatus = true;
        this.cateTypeWsBegin = e.screenX;
      }
    },
    typeScrollMove(e) {
      if (this.page_platform === "windows") {
        e.preventDefault();
        if (this.cateTypeWsStatus && this.$refs["articleContentOut"]) {
          this.cateTypeWsEnd = e.screenX;

          this.cateTypeWsDis = (this.cateTypeWsEnd - this.cateTypeWsBegin) / 50;

          let scrollLeft = this.$refs["articleContentOut"].scrollLeft;

          this.$refs["articleContentOut"].scrollTo(
            scrollLeft - this.cateTypeWsDis,
            0
          );
        }
      }
    },
    typeScrollUp() {
      if (this.page_platform === "windows") {
        this.cateTypeWsStatus = false;
      }
    },

    showHideSet() {
      this.$store.commit("crowd/set_bagConType", "create");
      this.$emit("showHideSet");
    },
    async manageMyBag() {
      this.$refs.crowdManageDialog.show();

      let res = await this.myBagList({ current: 1, size: 1000 });

      this.myBayListPage =
        res.data && res.data.data && res.data.data.records
          ? res.data.data.records
          : [];
    },

    async changeCate(cateVal) {
      this.nowBagIdList = [];

      if (cateVal != this.currentCate) {
        this.nowSelectedBagId = "";
      }

      this.cateList.forEach((val, e) => {
        if (val.value == cateVal) {
          this.currentCate = val.value;
          this.$set(val, "selected", true);
        } else {
          this.$set(val, "selected", false);
        }
      });

      let defaultParams = {
        data: this.currentCate,
        params: {
          current: 1,
          size: 1000,
        },
      };

      let res = await this.defaultBagList(defaultParams);

      let resList =
        res.data && res.data.data && res.data.data.records
          ? res.data.data.records
          : [];

      this.typeList = resList.filter((val) => {
        return val.packState == 1;
      });

      this.typeList.forEach((val) => {
        this.nowBagIdList.push(val.id);
      });

      setTimeout(() => {
        if (
          this.nowSelectedBagId &&
          this.nowBagIdList.includes(this.nowSelectedBagId)
        ) {
          this.typeList.forEach((val, e) => {
            if (val.id === this.nowSelectedBagId) {
              this.$set(val, "selected", true);
            } else {
              this.$set(val, "selected", false);
            }
          });
        } else {
          this.changeType(0);
        }
      }, 10);
    },
    changeType(index) {
      let params = {};

      if (this.typeList && this.typeList.length > 0) {
        params = { data: this.typeList[index] };

        this.typeList.forEach((val, e) => {
          if (e == index) {
            this.nowSelectedBagId = val.id;
            this.$set(val, "selected", true);
          } else {
            this.$set(val, "selected", false);
          }
        });

        this.editBag(params.data);
      }

      this.$emit("change", params);
    },
    updatePage() {
      this.changeCate(this.currentCate);
    },
    enDisBag(item, index) {
      let enDisResult = item.packState == 1 ? 2 : 1;

      let params = {
        id: item.id,
        packState: enDisResult,
        packType: item.packType,
      };

      this.$confirm(this.$t("edpBackendCommon.actionConfirm"), "", {
        confirmButtonText: this.$t("edpBackendCommon.define"),
        cancelButtonText: this.$t("edpBackendCommon.canale"),
        customClass: "edp-front-confirm",
        showClose: false,
      }).then(async () => {
        let res = await this.updateMyBag(params);

        if (res.data.success) {
          this.$message({
            type: "success",
            message: this.$t("edpBackendCommon.actionSuccess"),
          });

          this.$set(this.myBayListPage[index], "packState", enDisResult);

          this.changeCate(this.currentCate);
        }
      });
    },
    deleteBag(item, index) {
      let enDisResult = item.packState == 1 ? 2 : 1;

      let params = {
        id: item.id,
        packState: enDisResult,
        packType: this.currentCate,
      };

      this.$confirm(this.$t("edpBackendCommon.actionConfirm"), "", {
        confirmButtonText: this.$t("edpBackendCommon.define"),
        cancelButtonText: this.$t("edpBackendCommon.canale"),
        customClass: "edp-front-confirm",
        showClose: false,
      }).then(async () => {
        let res = await this.deleteMyBag(params);

        if (res.data.success) {
          this.$message({
            type: "success",
            message: this.$t("edpBackendCommon.actionSuccess"),
          });

          this.myBayListPage.splice(index, 1);

          this.changeCate(this.currentCate);
        }
      });
    },
    editBag(item) {
      this.$store.commit("crowd/set_bagConType", "update");
      this.$emit("editBag", item);
      this.$refs.crowdManageDialog.closeFn();
    },
  },
  mounted() {
    this.changeCate(1);

    window.addEventListener("mousemove", this.typeScrollMove);
    window.addEventListener("mouseup", this.typeScrollUp);
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-crowdChange {
  width: 100%;
  background: #fff;
}
.crowdChange-cate {
  display: flex;
  width: 100%;
  padding: toPad(30) toPad(40) toPad(15) toPad(40);
  align-items: center;

  ul {
    display: flex;
    align-items: flex-end;
    flex-basis: 100%;
  }

  li {
    font-size: toPad(18);
    margin-right: toPad(20);
    opacity: 0.7;
    cursor: pointer;

    &.selected {
      font-size: toPad(20);
      opacity: 1;
    }
  }
}

.crowdChange-type {
  display: block;
  width: 100%;
  padding: toPad(4) toPad(40) toPad(20) toPad(40);

  .crowdChange-type__iconContent {
    position: relative;
    width: 100%;
    height: 0;
    z-index: 0;

    .crowdChange-type__iconContent__left {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: toPad(10) solid transparent;
      border-right: toPad(10) solid #000;
      border-bottom: toPad(10) solid transparent;
      position: absolute;
      left: toPad(-20);
      top: toPad(10);
      cursor: pointer;
    }
    .crowdChange-type__iconContent__right {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: toPad(10) solid transparent;
      border-left: toPad(10) solid #000;
      border-bottom: toPad(10) solid transparent;
      position: absolute;
      right: toPad(-20);
      top: toPad(10);
      cursor: pointer;
    }
  }
  .crowdChange-type__articleContent {
    position: relative;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  ul {
    display: flex;
    width: 100%;
    height: toPad(42);
    flex-wrap: nowrap;

    li {
      display: inline-block;
      padding: toPad(7) toPad(15);
      font-size: toPad(16);
      line-height: 1;
      cursor: pointer;
      background: rgba(170, 183, 182, 0.3);
      border-radius: toPad(20);
      color: #0c0c0c;
      margin-right: 20px;
      margin-bottom: toPad(6);
      margin-top: toPad(6);
      float: left;
      box-sizing: content-box;
      flex-shrink: 0;

      &.selected {
        color: #3e886d;
        background: linear-gradient(0deg, #d8e7e2, #d8e7e2),
          rgba(170, 183, 182, 0.3);
      }
    }
  }
}

.edpPage_pc {
  .crowdChange-type {
    ul {
      li {
        font-size: toPad(14);
      }
    }
  }
}

.crowdChange-type__control {
  display: inline-flex;
  flex-shrink: 0;

  .crowdChange-type__control__btn {
    display: inline-flex;
    margin: 0 0 0 toPad(30);
    font-size: toPad(12);
    padding: toPad(3) 0 toPad(5) toPad(30);
    cursor: pointer;
    color: #000;

    &:nth-of-type(1) {
      background: url("~@/src/assets/crowd/select.png") no-repeat 0 top;
      background-size: toPad(24) toPad(24);
    }
    &:nth-of-type(2) {
      background: url("~@/src/assets/crowd/crowd-manage.png") no-repeat 0 top;
      background-size: toPad(24) toPad(24);
    }
  }
}

.crowd-manage-dialog {
  width: toPad(800) !important;

  .crowd-manage-dialog__content {
    width: 100%;
    padding-top: toPad(20);
    position: relative;
    z-index: 2;
  }
  .crowd-manage-dialog__ul {
    display: flex;
    width: 100%;
    background: #f1f1f1;
    opacity: 0.75;

    li {
      display: inline-block;
      padding: toPad(9) toPad(12);
      font-size: toPad(13);
      opacity: 0.4;
    }
  }

  .crowd-manage-dialog__tableContent {
    width: 100%;
    max-height: toPad(230);
    overflow-y: auto;
  }

  .crowd-manage-dialog__dl {
    display: flex;
    width: 100%;
    border-top: 1px solid #ddd;

    dd {
      display: inline-flex;
      padding: toPad(12);
      font-size: toPad(15);
      color: #000;
      line-height: 1.4;
      flex-wrap: wrap;
      word-break: break-all;
      word-wrap: break-word;

      .crowd-manage-dialog__sLine {
        position: relative;
        margin: 0 toPad(13);
        color: #d4d4d4;
      }
    }
  }
  .crowd-manage-dialog__control {
    white-space: nowrap;
    cursor: pointer;

    &.primary {
      color: #3e886d;
    }
  }
}
</style>
