<template>
  <div class="edp-crowdType">
    <ul>
      <li
        v-for="(item, index) in typeList"
        :key="'crowdTypeitem' + index"
        :class="{ selected: item.selected }"
        @click="crowdTypeChange(index)"
      >
        {{ item.name }}
      </li>

      <li class="tNum">{{ memberNum }}</li>
    </ul>

    <div class="edp-crowdType__control">
      <span
        class="edp-crowdType__controlBtn primary"
        v-if="outIndex == 0"
        @click="exportList"
        >{{ $t("crowd.exportList") }}</span
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  props: ["outIndex", "memberNum"],
  name: "crowdType",
  data() {
    return {
      typeList: [
        { name: this.$t("crowd.crodTypeMember") },
        { name: this.$t("crowd.crodTypeChart") },
      ],
    };
  },
  methods: {
    crowdTypeChange(index) {
      this.typeList.forEach((val, e) => {
        if (e == index) {
          this.$set(val, "selected", true);
          this.$emit("change", index);
        } else {
          this.$set(val, "selected", false);
        }
      });
    },
    exportList() {
      this.$emit("exportList");
    },
  },
  mounted() {
    this.typeList.forEach((val, e) => {
      if (e == 0) {
        this.$set(val, "selected", true);
      } else {
        this.$set(val, "selected", false);
      }
    });
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-crowdType {
  display: flex;
  width: 100%;
  padding: toPad(20) toPad(40) 0 toPad(40);

  ul {
    display: inline-flex;
    width: 50%;
    flex-shrink: 0;
    align-items: center;

    li {
      display: inline-block;
      cursor: pointer;
      font-size: toPad(16);
      margin-left: toPad(22);
      opacity: 0.5;
      line-height: 1;

      &:first-child {
        margin-left: 0;
      }
      &.selected {
        font-size: toPad(20);
        font-weight: bold;
        opacity: 1;
      }
      &.tNum {
        margin-left: toPad(4);
        font-size: toPad(20);
        opacity: 1;
        color: #3e886d;
        font-weight: bold;
        font-family: JLREmeric;
      }
    }
  }

  .edp-crowdType__control {
    display: flex;
    width: 50%;
    flex-shrink: 0;
    justify-content: flex-end;
  }
}
</style>
