<template>
  <div class="edp-crowdSet bf-op">
    <section class="edp-crowdSet__top">
      <label>{{ $t("crowd.setSearchTitle") }}</label>
      <p>{{ $t("crowd.set_remarks_top") }}</p>
      <em @click="showHideSet" :class="{ selected: showHideSetStatus }"></em>
    </section>

    <section class="edp-crowdSet__remarksNotice">
      {{ $t("crowd.set_remarks_notice") }}
    </section>

    <div class="edp-crowdSet__content" v-if="showHideSetStatus">
      <!-- edp-crowdSet__condition -->
      <section class="edp-crowdSet__condition">
        <div class="ecCond-top">
          <em @click="changeBagName"></em>

          <input
            type="text"
            v-model="bagName"
            v-if="bagNameStatus"
            ref="bagName_text"
            maxlength="60"
            @blur="bagNameStatus = false"
          />

          <label @click="changeBagName" v-else>{{ bagName }}</label>
        </div>

        <div class="ecCond-middle">
          <crowd-tree
            :dataTree="dataTree"
            @changeType="changeTypeFn"
            @add="dataTreeAdd"
            @delete="dataTreeDelete"
            @select="selectFn"
            @condTreeChange="cond1Change"
            @deleteCondLst="cond3Delete"
            v-if="treeVisible"
          />
        </div>

        <div
          class="edp-crowdSet__condition__opacity"
          v-if="
            !(
              !bagId ||
              (bagId &&
                commonCrowdChecked &&
                userInfoMap.roles &&
                userInfoMap.roles.includes('pack_admin')) ||
              (bagId && !commonCrowdChecked)
            )
          "
        ></div>
      </section>
      <!-- edp-crowdSet__condition end -->

      <section class="edp-crowdSet__bottom">
        <div class="edp-crowdSet__bottom__detail">
          {{ $t("crowd.bottomDetailTitle") }}
          {{ condNum }}
          {{ $t("crowd.bottomDetailUnit") }}
        </div>

        <div
          style="flex-shrink: 0"
          v-if="
            !bagId ||
            (bagId &&
              commonCrowdChecked &&
              userInfoMap.roles &&
              userInfoMap.roles.includes('pack_admin')) ||
            (bagId && !commonCrowdChecked)
          "
        >
          <el-checkbox
            v-model="commonCrowdChecked"
            v-if="userInfoMap.roles && userInfoMap.roles.includes('pack_admin')"
            >{{ $t("crowd.setCommonCrowd") }}</el-checkbox
          >

          <span class="edp-crowdType__controlBtn" @click="clearCrowdSet">{{
            $t("edpBackendCommon.clear")
          }}</span>

          <span
            class="edp-crowdType__controlBtn"
            @click="sub_bagSearch('saveOn')"
            >{{ $t("crowd.bagSaveOn") }}</span
          >

          <span
            class="edp-crowdType__controlBtn"
            @click="sub_bagSearch('save')"
            >{{ $t("crowd.bagSave") }}</span
          >

          <span
            class="edp-crowdType__controlBtn primary"
            @click="sub_bagSearch('search')"
            >{{ $t("crowd.bagSearch") }}</span
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { Toast } from "vant";
import crowdTree from "./crowdTree.vue";

export default {
  props: ["bagId", "bagState"],
  components: { crowdTree },
  name: "crowdSet",
  data() {
    return {
      showHideSetStatus: true,

      bagName: this.$t("crowd.set_crowdBag"),
      bagNameStatus: false,

      dataTreeItem: {
        value1: "",
        value2: "",
        value3: "",
        value2List: [],
        value3List: [],
      },
      dataTree: [
        {
          type: "or",
          list: [
            {
              type: "or",
              list: [
                {
                  value1: "",
                  value2: "",
                  value3: "",
                  value2List: [],
                  value3List: [],
                },
                {
                  value1: "",
                  value2: "",
                  value3: "",
                  value2List: [],
                  value3List: [],
                },
              ],
            },
            {
              type: "or",
              list: [
                {
                  value1: "",
                  value2: "",
                  value3: "",
                  value2List: [],
                  value3List: [],
                },
              ],
            },
          ],
        },
      ],
      treeVisible: false,
      commonCrowdChecked: false,
    };
  },

  computed: {
    ...mapState({
      cond1List: (state) => state.crowd.cond1List,
      bagConType: (state) => state.crowd.bagConType,
      userInfoMap: (state) => state.login.userInfo,
    }),
    condNum() {
      let result = 0;

      this.dataTree[0].list.forEach((val) => {
        val.list.forEach((val2) => {
          result += 1;
        });
      });

      return result;
    },
  },

  methods: {
    ...mapActions({
      createMyBag: "crowd/createMyBag",
      myBagDetail: "crowd/myBagDetail",
      bagCond1: "crowd/bagCond1",
      fieldValueOptionUri: "crowd/fieldValueOptionUri",

      updateMyBag: "crowd/updateMyBag",
    }),
    clearCrowdSet() {
      this.dataTree = [
        {
          type: "or",
          list: [
            {
              type: "or",
              list: [
                {
                  value1: "",
                  value2: "",
                  value3: "",
                  value2List: [],
                  value3List: [],
                },
                {
                  value1: "",
                  value2: "",
                  value3: "",
                  value2List: [],
                  value3List: [],
                },
              ],
            },
            {
              type: "or",
              list: [
                {
                  value1: "",
                  value2: "",
                  value3: "",
                  value2List: [],
                  value3List: [],
                },
              ],
            },
          ],
        },
      ];

      this.$forceUpdate();
    },
    async pageInit() {
      let params = {
        id: this.bagId,
      };

      let res = await this.myBagDetail(params);

      let resData = res.data.data;

      this.bagName = this.utils.formatLang(
        this.$i18n.locale,
        resData.packCnName,
        resData.packEnName
      );

      this.commonCrowdChecked = resData.packType == 1 ? true : false;

      this.dataTree = [{ type: resData.whereJson.join, list: [] }];

      resData.whereJson.conditionGroup.forEach((val, e) => {
        this.dataTree[0].list.push({ type: val.join, list: [] });
      });

      resData.whereJson.conditionGroup.forEach((val, e) => {
        val.fieldGroup.forEach((val2, e2) => {
          this.dataTree[0].list[e].list.push({
            fieldId: val2.fieldId,
            operateSymbol: val2.operateSymbol,
            value: val2.value,
            value1: val2.fieldId,
            value1Name: "",
            value2: val2.operateSymbol,
            value2Name: "",
            value2List: [],
            value3: val2.value,
            value3Name: "",
            value3List: [],
          });
        });
      });

      let _this = this;

      function treeInit(data) {
        data.forEach((valOut, eOut) => {
          if (valOut.queryFieldDtos && valOut.queryFieldDtos.length > 0) {
            valOut.queryFieldDtos.forEach((val, e) => {
              _this.dataTree[0].list.forEach((valDataPage, eDataPage) => {
                valDataPage.list.forEach(async (valDataIn, eDataIn) => {
                  if (valDataIn.fieldId == val.id) {
                    valDataIn.value1Name = _this.utils.formatLang(
                      _this.$i18n.locale,
                      val.fieldCommentCnName,
                      val.fieldCommentEnName
                    );

                    let list2Ls = JSON.parse(JSON.stringify(val.operateSymbol));

                    list2Ls.forEach((valLs) => {
                      valLs.cName = valLs.labelCnName;
                      valLs.eName = valLs.labelEnName;

                      if (valLs.value === valDataIn.value2) {
                        valDataIn.value2Name = _this.utils.formatLang(
                          _this.$i18n.locale,
                          valLs.labelCnName,
                          valLs.labelEnName
                        );
                      }
                    });

                    valDataIn.value2List = list2Ls;

                    if (val.fieldType === "array") {
                      valDataIn.value3Name = [];
                    } else {
                      valDataIn.value3Name = "";
                    }

                    if (val.fieldValueType === "remote") {
                      valDataIn.dataType =
                        val.fieldType === "number"
                          ? "arraySingle"
                          : val.fieldType;

                      let urlLs =
                        process.env.NODE_ENV === "development"
                          ? "api" + val.fieldValueOptionUri
                          : val.fieldValueOptionUri;

                      let dataParams = {
                        url: urlLs,

                        params: {
                          fieldId: val.id,
                        },
                      };

                      let resData3 = await _this.fieldValueOptionUri(
                        dataParams
                      );

                      let list3Ls = resData3.data.data || [];

                      list3Ls.forEach((valLs) => {
                        valLs.cName = valLs.labelCnName;
                        valLs.eName = valLs.labelEnName;

                        if (val.fieldType === "array") {
                          if (valDataIn.value3.includes(valLs.value)) {
                            valDataIn.value3Name.push(
                              _this.utils.formatLang(
                                _this.$i18n.locale,
                                valLs.labelCnName,
                                valLs.labelEnName
                              )
                            );
                          }
                        } else {
                          if (valLs.value === valDataIn.value3) {
                            valDataIn.value3Name = _this.utils.formatLang(
                              _this.$i18n.locale,
                              valLs.labelCnName,
                              valLs.labelEnName
                            );
                          }
                        }
                      });

                      valDataIn.value3List = list3Ls || [];
                    } else {
                      let list3Ls = val.fieldValueOption
                        ? JSON.parse(JSON.stringify(val.fieldValueOption))
                        : [];

                      list3Ls.forEach((valLs) => {
                        valLs.cName = valLs.labelCnName;
                        valLs.eName = valLs.labelEnName;

                        if (val.fieldType === "array") {
                          if (valDataIn.value3.includes(valLs.value)) {
                            valDataIn.value3Name.push(
                              _this.utils.formatLang(
                                _this.$i18n.locale,
                                valLs.labelCnName,
                                valLs.labelEnName
                              )
                            );
                          }
                        } else {
                          if (valLs.value === valDataIn.value3) {
                            valDataIn.value3Name = _this.utils.formatLang(
                              _this.$i18n.locale,
                              valLs.labelCnName,
                              valLs.labelEnName
                            );
                          }
                        }
                      });

                      valDataIn.value3List = list3Ls || [];

                      valDataIn.dataType = val.fieldType;
                    }
                  }
                });
              });
            });
          } else {
            if (valOut.children && valOut.children.length > 0) {
              treeInit(valOut.children);
            }
          }
        });
      }

      treeInit(this.cond1List);

      this.dataTree[0].list.forEach((val, e) => {
        val.list.forEach((val2, e2) => {
          if (val2.dataType === "array" || val2.dataType === "date") {
            typeof val2.value === "object"
              ? (val2.value3 = val2.value)
              : (val2.value3 = val2.value.toString().split(","));
          } else {
            val2.value3 = val2.value;
          }
        });
      });

      this.initTree(this.dataTree);
    },
    changeBagName() {
      this.bagNameStatus = true;

      this.$nextTick(() => {
        this.$refs["bagName_text"].focus();
      });
    },
    showHideSet() {
      this.showHideSetStatus = !this.showHideSetStatus;
    },
    show() {
      this.showHideSetStatus = true;
    },
    hide() {
      this.showHideSetStatus = false;
    },
    initTree(data) {
      data.forEach((val, e) => {
        if (val.list && val.list.length > 0) {
          val.list.forEach((val2, e2) => {
            val2.menuIndex = val.menuIndex + "-" + e2;
          });
          this.initTree(val.list);
        }
      });
    },
    aryEach(data, dataindex) {
      data.forEach((val, e) => {
        if (val.menuIndex == dataindex) {
          val.type === "and" ? (val.type = "or") : (val.type = "and");
        }

        if (val.list && val.list.length > 0) {
          this.aryEach(val.list, dataindex);
        }
      });

      this.$forceUpdate();
    },
    changeTypeFn(dataindex) {
      this.aryEach(this.dataTree, dataindex);
    },
    dataTreeAdd(data) {
      let itemLs = JSON.parse(JSON.stringify(this.dataTreeItem));

      if (data.indexIn || data.indexIn == 0) {
        this.dataTree[data.index].list[data.indexIn].list.push(itemLs);
      } else {
        this.dataTree[data.index].list.push({ type: "or", list: [itemLs] });
      }

      this.initTree(this.dataTree);
    },
    dataTreeDelete(data) {
      if (this.condNum > 1) {
        this.dataTree[data.index].list[data.indexIn].list.splice(
          data.indexLast,
          1
        );

        if (this.dataTree[data.index].list[data.indexIn].list.length == 0) {
          this.dataTree[data.index].list.splice(data.indexIn, 1);
        }
      }
    },
    addBag() {
      let itemLs = JSON.parse(JSON.stringify(this.dataTreeItem));

      if (this.dataTree.length == 0) {
        this.dataTree.push({
          type: "or",
          list: [],
        });
      }

      this.dataTree[0].list.push({ type: "or", list: [itemLs] });

      this.initTree(this.dataTree);
    },
    selectFn(data) {
      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        [data.dataKey],
        data.data
      );

      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        [data.dataNameKey],
        data.dataName
      );

      if (!data.dataType || data.dataType != "array") {
        this.$store.commit("login/set_dropDownTotalStatus", false);
      }
    },
    cond1Change(data) {
      // 条件1选择
      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        [data.dataKey],
        data.data
      );

      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        [data.dataNameKey],
        data.dataName
      );

      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        "fieldId",
        data.fieldId
      );

      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        "value2List",
        data.cond2queryData
      );

      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        "dataType",
        data.fieldType
      );

      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        "value3List",
        data.cond3queryData
      );

      if (data.dataKey === "value1") {
        // 重置后两个数据
        this.$set(
          this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
            data.indexInfo[2]
          ],
          "value2",
          ""
        );

        this.$set(
          this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
            data.indexInfo[2]
          ],
          "value2Name",
          ""
        );

        this.$set(
          this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
            data.indexInfo[2]
          ],
          "value3",
          ""
        );

        this.$set(
          this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
            data.indexInfo[2]
          ],
          "value3Name",
          ""
        );
      }

      this.$store.commit("login/set_dropDownTotalStatus", false);

      this.$forceUpdate();
    },
    cond3Delete(data) {
      let lastValueLs =
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ].value3;

      let lastValueNameLs =
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ].value3Name;

      lastValueLs.some((val, e) => {
        if (val === data.data) {
          lastValueLs.splice(e, 1);
          lastValueNameLs.splice(e, 1);
        }
      });
    },
    checkDateTree(data) {
      let result = true;

      data.some((val) => {
        val.list.some((val2) => {
          val2.list.some((val3) => {
            if (!val3.value1 || !val3.value2 || !val3.value3) {
              Toast(this.$t("crowd.treeEmpty"));
              result = false;
              return true;
            }
          });
        });
      });

      return result;
    },
    async sub_bagSearch(subType) {
      let checkempty = this.checkDateTree(this.dataTree);

      if (!checkempty) {
        return;
      }

      let subParams = {
        packCnName: this.bagName,
        packEnName: this.bagName,
        packType: this.commonCrowdChecked ? 1 : 2,
        packState: 0,

        whereJson: {
          join: this.dataTree[0].type,
          conditionGroup: [],
        },
      };

      this.dataTree[0].list.forEach((val) => {
        subParams.whereJson.conditionGroup.push({
          join: val.type,
          fieldGroup: [],
        });
      });

      this.dataTree[0].list.forEach((val, e) => {
        val.list.forEach((valLastList, eLastList) => {
          subParams.whereJson.conditionGroup.forEach((val2, e2) => {
            if (e == e2) {
              val2.fieldGroup.push({
                value:
                  typeof valLastList.value3 === "object"
                    ? valLastList.value3.join(",")
                    : valLastList.value3,

                operateSymbol: valLastList.value2,

                fieldId: valLastList.fieldId,
              });
            }
          });
        });
      });

      if (subType === "save" || subType === "saveOn") {
        let res = {};

        if (this.bagState) {
          subParams.packState = this.bagState;
        }

        if (this.bagConType === "update") {
          if (subType === "saveOn") {
            subParams.packState = 1;
          }
          subParams.id = this.bagId;
          res = await this.updateMyBag(subParams);
        } else {
          if (subType === "saveOn") {
            subParams.packState = 1;
          } else {
            subParams.packState = 0;
          }
          res = await this.createMyBag(subParams);
        }

        if (res.data.success) {
          Toast(this.$t("edpBackendCommon.actionSuccess"));

          if (subType === "saveOn") {
            this.$emit("saveOn");
          }

          this.bagConType === "update"
            ? this.$emit("update", "update")
            : this.$emit("update", "create");
        }
      }

      if (subType === "search") {
        this.$emit("search", subParams);

        this.showHideSetStatus = false;
      }
    },
  },
  async mounted() {
    let _this = this;

    let resCond1 = await this.bagCond1();

    this.$store.commit(
      "crowd/set_cond1List",
      resCond1.data && resCond1.data.data ? resCond1.data.data : []
    );

    this.dataTree.forEach((val, e) => {
      this.$set(val, "menuIndex", e);
    });

    this.$nextTick(() => {
      this.initTree(this.dataTree);

      this.treeVisible = true;

      this.$forceUpdate();
    });
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-crowdSet {
  position: relative;
  z-index: 5;
  width: 100%;
  padding: toPad(23) toPad(40) toPad(28) toPad(40);
  background: #fff;
  margin: toPad(7) 0 0 0;
}
.edp-crowdSet__top {
  display: flex;
  width: 100%;
  align-items: center;

  label {
    display: inline-block;
    flex-shrink: 0;
    font-weight: 600;
    font-size: toPad(20);
  }
  p {
    flex-basis: 100%;
    padding: 0 toPad(15);
    font-size: toPad(13);
    opacity: 0.5;
  }
  em {
    display: inline-block;
    width: toPad(17);
    height: toPad(17);
    background: url("~@/src/assets/crowd/icon-down.png") no-repeat center center;
    background-size: 100% auto;
    cursor: pointer;
    transition: transform 0.3s;

    &.selected {
      transform: rotate(180deg);
      transform-origin: center center;
    }
  }
}
.edp-crowdSet__remarksNotice {
  width: 100%;
  font-size: toPad(14);
  padding: toPad(15) 0 0 0;
  line-height: 1.4;
}
.edp-crowdSet__content {
  width: 100%;
}

.edp-crowdSet__condition {
  position: relative;
  width: 100%;
  z-index: 2;

  .edp-crowdSet__condition__opacity {
    position: absolute;
    z-index: 5;
    width: 105%;
    height: 100%;
    background: #fff;
    opacity: 0.6;
    left: -5%;
    top: 0;
  }
}
.ecCond-top {
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: toPad(27);

  input[type="text"] {
    width: toPad(460);
    background: transparent;
    font-size: toPad(14);
    color: #000;
    border: 0;
    padding: 0 toPad(20) 0 toPad(8);
  }
  em {
    display: inline-block;
    flex-shrink: 0;
    width: toPad(20);
    height: toPad(20);
    overflow: hidden;
    background: url("~@/src/assets/crowd/crowd-set.png") no-repeat center center;
    background-size: 100% auto;
    cursor: pointer;
  }
  label {
    display: inline-block;
    padding: 0 toPad(20) 0 toPad(8);
    font-size: toPad(14);
    background: url("~@/src/assets/crowd/crowd-edit.png") no-repeat right center;
    background-size: toPad(12) auto;
    cursor: pointer;
  }
}
.ecCond-middle {
  width: 100%;
  padding-top: toPad(25);
}
.edp-crowdSet__bottom {
  width: 100%;
  padding: toPad(23) 0 0 0;
  display: flex;
  align-items: center;

  .edp-crowdSet__bottom__detail {
    flex-basis: 100%;
    font-size: toPad(16);
    font-weight: bold;
  }
  .edp-crowdType__controlBtn {
    margin-left: toPad(14);
  }

  .el-checkbox {
    margin-right: toPad(6);

    .el-checkbox__label {
      font-size: toPad(14);
      color: #000;
    }
  }
}
</style>
