<template>
  <section>
    <div class="edp-crowd" v-show="pageDetailHide" v-if="refreshStatus">
      <edp-header-search
        :headerLeftWord="$t('crowd.headerLeftWord')"
        :searchPlaceHolder="$t('crowd.searchPlaceholder')"
        noSearch="true"
      >
      </edp-header-search>

      <div class="topTips fac">
        <img src="@/src/assets/member/notice.png" alt="" class="tip" />
        <span class="f14 op5"> {{ $t("member.dataRange2") }}</span>
      </div>

      <crowd-change
        @change="crowdChangeFn"
        @showHideSet="showHideSetFn"
        @editBag="editBagFn"
        ref="crowdChangeRef"
      />

      <crowd-set
        ref="crowdSetRef"
        v-if="crowdSetStatus"
        @update="crowdUpdateFn"
        @search="crowdSetSearch"
        @saveOn="crowdSaveOnFn"
        :bagId="bagId"
        :bagState="bagState"
      />

      <crowd-type
        @change="typeChangeFn"
        @exportList="exportListFn"
        :memberNum="memberNum"
        :outIndex="memberChartIndex"
      />

      <crowd-member
        v-if="memberChartIndex == 0"
        :paramsProps="memberParams"
        @pageDetailLink="memberDetailLink"
        ref="crowdMemberRef"
      />

      <crowd-chart
        :paramsProps="memberParams"
        ref="crowdChartRef"
        v-if="memberChartIndex == 1 && crowdChartEnable"
      />
    </div>

    <router-view v-if="!pageDetailHide" />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import crowdChange from "../components/crowd/crowdChange.vue";
import CrowdChart from "../components/crowd/crowdChart.vue";
import CrowdMember from "../components/crowd/crowdMember.vue";
import CrowdSet from "../components/crowd/crowdSet.vue";
import CrowdType from "../components/crowd/crowdType.vue";

export default {
  components: { crowdChange, CrowdSet, CrowdType, CrowdMember, CrowdChart },
  name: "crowd",
  data() {
    return {
      currentCate: "",
      memberChartIndex: 0,
      crowdSetStatus: false,
      memberParams: {},
      bagId: "",
      bagState: "",

      memberNum: "",

      pageDetailHide: true,
      pageFirstVisit: true,
      refreshStatus: false,

      crowdChartEnable: true,
    };
  },
  watch: {
    $route(val) {
      if (val.path.indexOf("memberDetail") != -1) {
        this.pageDetailHide = false;
      } else {
        this.pageDetailHide = true;

        if (this.pageFirstVisit) {
          this.pageFirstVisit = false;

          this.refreshStatus = true;
        }
      }
    },
  },
  methods: {
    ...mapActions({
      bagMemberExport: "crowd/bagMemberExport",
      bagMemberList: "crowd/bagMemberList",
    }),
    async initData() {
      if (this.memberChartIndex == 0) {
        setTimeout(() => {
          this.$refs.crowdMemberRef.pageInit();
        }, 10);
      }

      let params = { current: 1, size: 10 };

      if (
        this.memberParams &&
        (this.memberParams.id || this.memberParams.whereJson)
      ) {
        params = Object.assign(params, this.memberParams);

        let res = await this.bagMemberList(params);

        this.memberNum =
          res.data && res.data.data && res.data.data.total
            ? res.data.data.total
            : 0;
      } else {
        this.memberNum = 0;
      }
    },
    crowdChangeFn(data) {
      if (data && data.data) {
        this.memberParams = { id: data.data.id };
      } else {
        this.memberParams = {};
      }

      this.initData();

      if (this.$refs.crowdSetRef) {
        this.$refs.crowdSetRef.hide();
      }
    },
    typeChangeFn(index) {
      this.memberChartIndex = index;

      this.initData();
    },
    showHideSetFn(data) {
      this.bagId = "";
      this.crowdSetStatus = false;

      this.memberNum = 0;
      this.memberParams = {};

      setTimeout(() => {
        this.crowdSetStatus = true;

        if (this.$refs.crowdMemberRef) {
          this.$refs.crowdMemberRef.clearData();
        }

        if (this.$refs.crowdChartRef) {
          this.crowdChartEnable = false;

          setTimeout(() => {
            this.crowdChartEnable = true;
          }, 10);
        }
      }, 10);
    },
    crowdSetSearch(data) {
      this.memberParams = data;

      this.initData();
    },
    async exportListFn() {
      let res = await this.bagMemberExport(this.memberParams);

      const name = "crowd";
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      a.download = name + ".xlsx";
      document.body.appendChild(a);
      a.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 1000);
    },
    editBagFn(data) {
      this.bagId = data.id;
      this.bagState = data.packState;
      this.crowdSetStatus = true;

      setTimeout(() => {
        this.$refs.crowdSetRef.pageInit();
        this.$refs.crowdSetRef.show();
      }, 100);
    },
    crowdSaveOnFn() {
      this.$refs.crowdChangeRef.updatePage();
    },
    crowdUpdateFn(type) {
      if (type === "create") {
        this.crowdSetStatus = false;
      }
    },
    memberDetailLink(data) {
      this.$router.push({
        path: "/crowd/memberDetail",
        query: { id: data },
      });
    },
  },
  mounted() {
    if (this.$route.path.indexOf("memberDetail") != -1) {
      this.pageDetailHide = false;
    } else {
      this.pageDetailHide = true;

      if (this.pageFirstVisit) {
        this.pageFirstVisit = false;

        this.refreshStatus = true;
      }
    }
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-crowd {
  position: relative;
  z-index: 1;
}
.edp-crowdType__controlBtn {
  display: inline-flex;
  width: auto;
  min-width: toPad(94);
  justify-content: center;
  align-items: center;
  height: toPad(40);
  padding: 0 toPad(16);
  font-size: toPad(14);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  cursor: pointer;
  color: #000;
  margin-left: toPad(14);
  white-space: nowrap;
  flex-shrink: 0;

  &.primary {
    background: #3e886d;
    color: #fff;
  }

  &:first-child {
    margin-left: 0;
  }
}

.user-empty--crowd {
  padding: toPad(50) 0;
  text-align: center;

  img {
    width: toPad(164);
    height: toPad(142);
  }
}
</style>
