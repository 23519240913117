<template>
  <div class="edp-crowdMember">
    <div class="edp-crowdMember__list" v-if="!clearDataSt">
      <ul>
        <li
          v-for="(item, index) in dataList"
          :key="'cml' + index"
          @click="linkMember(item)"
        >
          <div class="edp-crowdMember__item bf-op">
            <img :src="item.photo || defaultHeadImage" alt="" />

            <div class="edp-crowdMember__itemContent">
              <section class="ec-itc-linetop">
                <span class="ec-itc-linetop__name"
                  ><span>{{
                    utils.formatLang($i18n.locale, item.cname, item.ename)
                  }}</span></span
                >

                <em v-if="item.empGenerations"
                  ><span>{{ item.empGenerations }}</span></em
                >
              </section>

              <section class="ec-itc-linemiddle">
                <em>{{ $t("member.Department") }}</em>
                <p>
                  {{
                    utils.formatLang($i18n.locale, item.dept, item.deptEname)
                  }}
                </p>
              </section>

              <section class="ec-itc-linemiddle">
                <em>{{ $t("member.Sub-department") }}</em>
                <p>{{ item.subdivision }}</p>
              </section>

              <section class="ec-itc-linemiddle">
                <em> {{ $t("member.Leader") }}</em>
                <p>
                  {{
                    utils.formatLang(
                      $i18n.locale,
                      item.directLeaderChineseName,
                      item.directLeaderEnName
                    )
                  }}
                </p>
              </section>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div
      v-if="
        ((!dataList || (dataList && dataList.length == 0)) && !firstIn) ||
        clearDataSt
      "
      class="user-empty--crowd tac"
    >
      <img src="@/src/assets/member/user-empty.png" alt="" class="mb16" />
      <p class="f14 tac">{{ $t("member.NoResults") }}</p>
    </div>

    <p v-show="loading" class="mb8 tac pt10 pb20">
      <van-loading
        type="spinner"
        size="24px"
        color="#464950"
        text-color="#464950"
        >{{ $t("member.loading") }}</van-loading
      >
    </p>

    <van-overlay
      :show="subLoading"
      z-index="120"
      :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
    >
      <div class="loading-wrap-common">
        <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
          >&nbsp;</van-loading
        >
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "crowdMember",
  props: ["paramsProps"],
  computed: {
    ...mapState({
      defaultHeadImage: (state) => state.login.defaultHeadImage,
    }),
  },
  data() {
    return {
      loading: false,
      firstIn: true,
      subLoading: false,
      dataList: [],
      pageNum: 1,
      pageSize: 15,
      hasMore: true,

      totalNum: "",

      clearDataSt: false,
    };
  },
  methods: {
    ...mapActions({
      bagMemberList: "crowd/bagMemberList",
    }),
    async pageInit(type) {
      if (!type) {
        this.subLoading = true;
        this.hasMore = true;
        this.dataList = [];
        this.pageNum = 1;
      }

      let params = {
        current: this.pageNum,
        size: this.pageSize,
      };

      if (
        this.paramsProps &&
        (this.paramsProps.id || this.paramsProps.whereJson)
      ) {
        params = Object.assign(params, this.paramsProps);

        let res = await this.bagMemberList(params);

        let resultList =
          res.data && res.data.data && res.data.data.records
            ? res.data.data.records
            : [];

        this.totalNum = res.data.data.total;

        if (resultList.length < this.pageSize) {
          this.hasMore = false;
        }

        if (type && type === "more") {
          this.dataList = this.dataList.concat(resultList);
        } else {
          this.dataList = resultList;
        }
      } else {
        this.dataList = [];
      }

      this.subLoading = false;
      this.clearDataSt = false;

      this.firstIn = false;

      this.loading = false;
    },
    clearData() {
      this.clearDataSt = true;
    },
    lazyLoad() {
      if (!this.subLoading && !this.clearDataSt) {
        let windowScrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;

        let windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;

        let docHeight = document.body.scrollHeight;

        if (
          windowScrollTop + windowHeight >= docHeight - 50 &&
          this.hasMore &&
          !this.loading
        ) {
          this.loading = true;

          this.pageNum += 1;

          this.pageInit("more");
        }
      }
    },
    linkMember(item) {
      let sercetCd = this.utils.encryption(item.cdsid);

      this.$emit("pageDetailLink", sercetCd);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.lazyLoad);
  },
  destroyed() {
    window.removeEventListener("scroll", this.lazyLoad);
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-crowdMember {
  width: 100%;

  .edp-crowdMember__list {
    width: 100%;
    padding: 0 toPad(26);
    overflow: hidden;

    ul {
      width: 100%;
      overflow: hidden;
      padding: 0 0 toPad(20) 0;

      li {
        width: 33.33%;
        height: toPad(156);
        float: left;
        padding: toPad(20) toPad(14) 0 toPad(14);
        cursor: pointer;
      }
      .edp-crowdMember__item {
        display: flex;
        width: 100%;
        height: 100%;
        box-shadow: 0px toPad(4) toPad(13) rgba(230, 236, 244, 0.6);
        border-radius: toPad(10);
        padding: toPad(15);
        overflow: hidden;

        img {
          display: block;
          width: toPad(80);
          height: toPad(80);
          flex-shrink: 0;
          border-radius: 100%;
        }
        .edp-crowdMember__itemContent {
          flex-basis: 100%;
          padding-left: toPad(20);
          overflow: hidden;
        }
        .ec-itc-linetop {
          display: flex;
          width: 100%;
          align-items: center;
          padding-bottom: toPad(10);

          .ec-itc-linetop__name {
            position: relative;
            display: inline-block;
            font-size: toPad(18);
            line-height: 1;
            padding-right: toPad(10);
            font-weight: bold;
            top: toPad(5);
            max-width: 75%;

            span {
              display: inline-block;
              padding-bottom: toPad(3);
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          em {
            display: inline-block;
            color: #fff;
            border-radius: toPad(5) 0px;
            background: linear-gradient(
              90.61deg,
              #60cfc6 3.65%,
              #61bfb4 99.54%
            );
            padding: 0 toPad(4);
            text-align: center;
            font-style: normal;
            border: 1px solid rgba(152, 244, 235, 0.6);
            line-height: toPad(16);
            flex-shrink: 0;

            span {
              transform: scale(0.9);
              font-size: toPad(12);
            }
          }
        }

        .ec-itc-linemiddle {
          display: flex;
          width: 100%;
          padding: toPad(6) 0 0 0;
          font-size: toPad(14);

          em {
            display: inline-block;
            font-style: normal;
            flex-shrink: 0;
            opacity: 0.5;
            line-height: 1;
            padding-bottom: toPad(3);
          }
          p {
            display: block;
            flex-basis: 100%;
            padding-left: toPad(15);
            padding-bottom: toPad(3);
            line-height: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
