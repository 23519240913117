<template>
  <section>
    <div class="edp-crowdChart" v-if="hasParams">
      <div class="chart-block fjsba">
        <div class="chart-item fjsb one">
          <div class="num-block">
            <div class="num-item">
              <p class="f16 op5 fb mb8" v-html="$t('moment.male-female')"></p>
              <p class="f26 fb">{{ datas.sexRatio || "-" }}</p>
            </div>
            <div class="num-item">
              <p class="f16 op5 fb mb8" v-html="$t('moment.Average')"></p>
              <p class="f28 fb">
                {{ datas.avgWorkYear === null ? "-" : datas.avgWorkYear
                }}<span class="f20"> {{ $t("moment.years") }}</span>
              </p>
            </div>
            <div class="num-item">
              <p class="f16 op5 fb mb8" v-html="$t('moment.AverageWork')"></p>
              <p class="f28 fb">
                {{ datas.avgWorkYear === null ? "-" : datas.avgJobYear
                }}<span class="f20"> {{ $t("moment.years") }}</span>
              </p>
            </div>
          </div>
          <div class="age-wrap">
            <p class="f16 fb">{{ $t("moment.agelayout") }}</p>
            <div class="list-empty" v-show="!ageData.data.length">
              <img
                class="empty-pic"
                src="@/src/assets/member/empty.png"
                alt=""
              />
              <p>{{ $t("member.Nodata") }}</p>
              <div class="per-empty"></div>
            </div>
            <div class="chart-wrap" id="age" v-show="ageData.data.length"></div>
          </div>
        </div>
        <div class="chart-item two">
          <p class="f16 fb">{{ $t("moment.departments") }}</p>
          <div class="list-empty" v-show="!depData.length">
            <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
            <p>{{ $t("member.Nodata") }}</p>
            <div class="per-empty"></div>
          </div>
          <div class="chart-wrap" id="dept" v-show="depData.length"></div>
        </div>
      </div>
      <div class="chart-block fjsba">
        <div class="chart-item thr">
          <p class="f16 fb">{{ $t("moment.gradesl") }}</p>
          <div class="list-empty" v-show="!gradesData.length">
            <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
            <p>{{ $t("member.Nodata") }}</p>
            <div class="per-empty"></div>
          </div>
          <div class="chart-wrap" id="grades" v-show="gradesData.length"></div>
        </div>
        <div class="chart-item four">
          <p class="f16 fb">
            {{ $t("moment.positionlayout") }}
            <el-tooltip
              class="item"
              effect="dark"
              placement="right"
              popper-class="draw_share_atooltip"
            >
              <div v-html="$t('moment.onlyTop5')" slot="content"></div>
              <img src="@/src/assets/member/tips.png" alt="" class="tips"
            /></el-tooltip>
          </p>
          <div class="list-empty" v-show="!positionData.length">
            <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
            <p>{{ $t("member.Nodata") }}</p>
            <div class="per-empty"></div>
          </div>
          <div
            class="chart-wrap"
            id="position"
            v-show="positionData.length"
          ></div>
        </div>
        <div class="chart-item num-block fcjsb five">
          <div class="num-item">
            <p class="f16 fb">{{ $t("moment.industrylayout") }}</p>
            <div class="list-empty indu-empty" v-show="!industryData.length">
              <img
                class="empty-pic"
                src="@/src/assets/member/empty.png"
                alt=""
              />
              <p>{{ $t("member.Nodata") }}</p>
              <div class="per-empty"></div>
            </div>
            <div class="ind-wrap" v-show="industryData.length">
              <p
                class="ind-item txt-elps"
                v-for="(item, i) in industryData"
                :key="i"
              >
                <span class="f12 cf fb">Top {{ i + 1 }}</span> {{ item.name }}
              </p>
            </div>
          </div>
          <div class="num-item avgs">
            <p class="f16 fb tit">{{ $t("moment.workingproportion") }}</p>
            <div class="avg-block fjsa">
              <div class="avg-item">
                <p class="f12 tac desc">{{ $t("moment.workinghours") }}</p>
                <p class="c63 f42 tac mb4">
                  {{ datas.avgManHour === null ? "-" : datas.avgManHour
                  }}<span class="f12">{{ $t("member.hours") }}</span>
                </p>
                <p class="f12 tac desc">
                  <span class="c6c tac" v-if="datas.avgManHourVsSum === null">
                    - %
                  </span>
                  <span class="c6c tac" v-if="datas.avgManHourVsSum >= 0">
                    <i class="el-icon-caret-top c6c f16"></i>
                    {{ datas.avgManHourVsSum }}%
                  </span>
                  <span class="cr tac" v-if="datas.avgManHourVsSum < 0">
                    <i class="el-icon-caret-bottom cr f16"></i>
                    {{ Math.abs(datas.avgManHourVsSum) }}%
                  </span>
                  {{ $t("moment.CompAverage") }}
                </p>
              </div>
              <div class="avg-line"></div>
              <div class="avg-item">
                <p class="f12 tac desc">{{ $t("moment.onsiteproportion") }}</p>
                <p class="cfb f42 tac mb4">
                  {{
                    datas.fieldOfficeRate === null
                      ? "-"
                      : datas.fieldOfficeRate
                  }}<span class="f12">%</span>
                </p>
                <p class="f12 tac desc">
                  <span
                    class="c6c tac"
                    v-if="datas.fieldOfficeRateVsSum === null"
                  >
                    - %
                  </span>
                  <span class="c6c tac" v-if="datas.fieldOfficeRateVsSum >= 0">
                    <i class="el-icon-caret-top c6c f16"></i>
                    {{ datas.fieldOfficeRateVsSum }}%
                  </span>
                  <span class="cr tac" v-if="datas.fieldOfficeRateVsSum < 0">
                    <i class="el-icon-caret-bottom cr f16"></i>
                    {{ Math.abs(datas.fieldOfficeRateVsSum) }}%
                  </span>
                  {{ $t("moment.CompAverage") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-block fjsba">
        <div class="chart-item six">
          <p class="f16 fb">{{ $t("moment.graduation") }}</p>
          <div class="list-empty" v-show="!graduationData.data.length">
            <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
            <p>{{ $t("member.Nodata") }}</p>
            <div class="per-empty"></div>
          </div>
          <div
            class="chart-wrap"
            id="graduation"
            v-show="graduationData.data.length"
          ></div>
        </div>
        <div class="chart-item seven">
          <div class="score-wrap">
            <p class="f16 fb">{{ $t("moment.360Score") }}</p>
            <div class="list-empty" v-show="!scoreData.length">
              <img
                class="empty-pic"
                src="@/src/assets/member/empty.png"
                alt=""
              />
              <p>{{ $t("member.Nodata") }}</p>
              <div class="per-empty"></div>
            </div>
            <div class="chart-wrap" id="score" v-show="scoreData.length"></div>
          </div>
          <div class="perfor-wrap">
            <p class="f16 fb plr20">{{ $t("moment.PerScore") }}</p>
            <div
              class="list-empty"
              v-show="datas.avgPerf == null || datas.avgPerfVsSum == null"
            >
              <img
                class="empty-pic"
                src="@/src/assets/member/empty.png"
                alt=""
              />
              <p>{{ $t("member.Nodata") }}</p>
              <div class="per-empty"></div>
            </div>
            <template
              v-if="datas.avgPerf !== null || datas.avgPerfVsSum !== null"
            >
              <div class="number-chart fjc">
                <div class="num-above f14 cf tac">
                  <span class="f60 fb">{{ datas.avgPerf }}</span
                  >{{ $t("moment.Points") }}
                </div>
                <div class="num-below"></div>
              </div>
              <p class="f14 tac mb10">{{ $t("moment.AvgPerScore") }}</p>
              <div class="f12 c6c tac mb4" v-if="datas.avgPerfVsSum === null">
                <i class="el-icon-caret-top c6c f16"></i>
                — %
              </div>
              <div class="f12 c6c tac mb4" v-if="datas.avgPerfVsSum >= 0">
                <i class="el-icon-caret-top c6c f16"></i>
                {{ datas.avgPerfVsSum }}%
              </div>
              <div class="f12 cr tac mb4" v-if="datas.avgPerfVsSum < 0">
                <i class="el-icon-caret-bottom cr f16"></i>
                {{ Math.abs(datas.avgPerfVsSum) }}%
              </div>
              <p class="f10 tac">{{ $t("moment.CompAverage") }}</p>
            </template>
          </div>
        </div>
      </div>
      <van-overlay
        :show="subLoading"
        z-index="120"
        :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
      >
        <div class="loading-wrap-common">
          <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
            >&nbsp;</van-loading
          >
        </div>
      </van-overlay>
    </div>

    <div v-else class="user-empty--crowd tac">
      <img src="@/src/assets/member/user-empty.png" alt="" class="mb16" />
      <p class="f14 tac">{{ $t("member.NoResults") }}</p>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import * as echarts from "echarts";
export default {
  name: "crowdChart",
  props: ["paramsProps"],
  data() {
    return {
      deptChart: null,
      positionChart: null,
      gradesChart: null,
      graduationChart: null,
      industryChart: null,
      ageChart: null,
      scoreChart: null,
      subLoading: false,
      datas: {
        avgManHour: 0,
        avgWorkYear: 0,
        fieldOfficeRate: 0,
        sexRatio: "",
        avgJobYear: 0,
        avgPerf: null,
        avgPerfVsSum: null,
      },
      depData: [{}],
      positionData: [{}],
      gradesData: [{}],
      graduationData: {
        xdata: [],
        data: [{}],
      },
      industryData: [{}],
      ageData: {
        xdata: [],
        data: [{}],
      },
      scoreData: [{}],
      emptyData: 0,
      hasParams: true,
    };
  },
  watch: {
    paramsProps: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal, newVal === oldVal);
        if (JSON.stringify(newVal) == "{}") {
          this.hasParams = false;
        } else {
          this.hasParams = true;
        }
        if (JSON.stringify(newVal) != "{}" && newVal !== oldVal) {
          setTimeout(() => {
            this.getData(newVal);
          }, 200);
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      crowdPortrait: "crowd/crowdPortrait",
    }),
    async getData(val) {
      let params;
      if (val.id) {
        params = {
          id: val.id,
        };
      } else {
        params = {
          whereJson: val.whereJson,
        };
      }
      this.subLoading = true;
      let res = await this.crowdPortrait(params);
      let datas = res.data.data;
      this.subLoading = false;

      this.datas = datas;
      this.depData = [];
      for (const key in datas.deptRatio) {
        let item = {
          name: key,
          value: datas.deptRatio[key],
        };
        this.depData.push(item);
      }
      this.drawDept();
      this.positionData = [];
      for (const key in datas.positionRatio) {
        let item = {
          name: key,
          value: datas.positionRatio[key],
        };
        this.positionData.push(item);
      }
      this.drawPosition();
      this.gradesData = [];
      for (const key in datas.empRankRatio) {
        let item = {
          name: key,
          value: datas.empRankRatio[key],
        };
        this.gradesData.push(item);
      }
      this.drawGrades();
      this.graduationData.xdata = [];
      this.graduationData.data = [];
      for (const key in datas.departmentRatio) {
        this.graduationData.xdata.push(key);
        this.graduationData.data.push(datas.departmentRatio[key]);
      }
      this.drawGraduation();

      this.industryData = [];
      for (const key in datas.tradeRatio) {
        let item = {
          name: key,
          value: datas.tradeRatio[key],
        };
        this.industryData.push(item);
      }

      this.ageData.xdata = [];
      this.ageData.data = [];
      for (const key in datas.ageRatio) {
        this.ageData.xdata.unshift(key);
        this.ageData.data.unshift(datas.ageRatio[key]);
      }
      this.drawAge();
      this.scoreData = [];
      this.emptyData = 0;
      for (const key in datas.scoreRatio) {
        let item = {
          name: key,
          value: datas.scoreRatio[key],
        };
        this.emptyData += datas.scoreRatio[key];
        this.scoreData.push(item);
      }
      this.drawScore();
    },
    drawDept() {
      let option;
      option = {
        color: ["#FF8A8A", "#FBCD64", "#61D6CC", "rgba(170, 183, 182, 0.3)"],
        tooltip: {
          trigger: "item",
          formatter: "{b}\n{c}%",
        },
        grid: {
          top: 0,
          bottom: 5,
          left: 0,
          right: 0,
          containLabel: false,
        },
        series: [
          {
            type: "pie",
            radius: ["35%", "62%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 4,
              // borderColor: "#fff",
              // borderWidth: 1,
            },
            label: {
              show: true,
              position: "outside",
              formatter: "{b}\n{fb|{c}%}",
              lineHeight: 18,
              fontSize: this.utils.transferPx(12),
              rich: {
                fb: {
                  fontWeight: "bold",
                  fontSize: this.utils.transferPx(12),
                },
              },
            },
            labelLine: {
              show: true,
              length: 15,
              length2: 30,
            },
            labelLayout: (params) => {
              const isLeft = params.labelRect.x < this.deptChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points,
              };
            },
            data: this.depData,
          },
        ],
      };
      let chartDom = document.getElementById("dept");
      if (this.deptChart && this.deptChart.id) {
        this.deptChart.clear();
      }
      this.deptChart = echarts.init(chartDom);
      option && this.deptChart.setOption(option);
      $(window).resize(() => {
        this.deptChart.resize();
      });
    },
    drawPosition() {
      let option;
      option = {
        color: [
          "#61BFB4",
          "#61D6CC",
          "#83DC8A",
          "#FDCE61",
          "#F1C96D",
          "#DBE5DE",
          "#E8EEEC",
          "#647074",
        ],
        tooltip: {
          trigger: "item",
          formatter: "{b}\n{c}%",
        },
        series: [
          {
            type: "pie",
            radius: ["16%", "75%"],
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            label: {
              show: true,
              position: "outside",
              formatter: "{b}\n{fb|{c}%}",
              lineHeight: 18,
              fontSize: this.utils.transferPx(12),
              rich: {
                fb: {
                  fontWeight: "bold",
                  fontSize: this.utils.transferPx(12),
                },
              },
            },
            labelLine: {
              show: true,
              length: 20,
              length2: 10,
            },
            labelLayout: (params) => {
              const isLeft =
                params.labelRect.x < this.positionChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points,
              };
            },
            data: this.positionData,
          },
        ],
      };
      let chartDom = document.getElementById("position");
      if (this.positionChart && this.positionChart.id) {
        this.positionChart.clear();
      }
      this.positionChart = echarts.init(chartDom);
      option && this.positionChart.setOption(option);
      $(window).resize(() => {
        this.positionChart.resize();
      });
    },
    drawGrades() {
      let option;
      option = {
        color: ["#61D6CC", "#61BFB4", "#FFD36C", "#FF8A8A", "#C6A7D3"],
        tooltip: {
          trigger: "item",
          formatter: "{b}\n{c}%",
        },
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            radius: ["32%", "66%"],
            avoidLabelOverlap: true,
            label: {
              show: true,
              position: "outside",
              formatter: "{b}\n{fb|{c}%}",
              lineHeight: 18,
              fontSize: this.utils.transferPx(12),
              rich: {
                fb: {
                  fontWeight: "bold",
                  fontSize: this.utils.transferPx(12),
                },
              },
            },
            labelLine: {
              show: true,
              length: 20,
              length2: 10,
            },
            labelLayout: (params) => {
              const isLeft =
                params.labelRect.x < this.gradesChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points,
              };
            },
            data: this.gradesData,
          },
        ],
      };
      let chartDom = document.getElementById("grades");
      if (this.gradesChart && this.gradesChart.id) {
        this.gradesChart.clear();
      }
      this.gradesChart = echarts.init(chartDom);
      option && this.gradesChart.setOption(option);
      $(window).resize(() => {
        this.gradesChart.resize();
      });
    },
    drawGraduation() {
      let option;
      let color = [
        new echarts.graphic.LinearGradient(0, 1, 0, 0, [
          {
            offset: 0.14,
            color: "#F0C86D",
          },
          {
            offset: 0.9988,
            color: "#FFCE5F",
          },
        ]),
        new echarts.graphic.LinearGradient(0, 1, 0, 0, [
          {
            offset: 0.2213,
            color: "#61BFB4",
          },
          {
            offset: 0.8434,
            color: "#88C8A8",
          },
        ]),
        new echarts.graphic.LinearGradient(0, 1, 0, 0, [
          {
            offset: 0.65,
            color: "#8EB691",
          },
          {
            offset: 0.9817,
            color: "#81E088",
          },
        ]),
      ];
      option = {
        grid: {
          top: 30,
          bottom: 5,
          left: 20,
          right: 20,
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          type: "category",
          data: this.graduationData.xdata,
          axisLabel: {
            interval: 0,
            color: "rgba(0,0,0,0.5)",
            fontSize: this.utils.transferPx(10),
            width: 96,
            overflow: "truncate",
          },
          axisLine: { lineStyle: { color: "rgba(0,0,0,0.5)" } },
        },
        yAxis: {
          type: "value",
          minInterval: 1,
        },
        series: [
          {
            data: this.graduationData.data,
            type: "bar",
            color: color,
            colorBy: "data",
            barMaxWidth: 50,
          },
        ],
      };
      let chartDom = document.getElementById("graduation");
      if (this.graduationChart && this.graduationChart.id) {
        this.graduationChart.clear();
      }
      this.graduationChart = echarts.init(chartDom);
      option && this.graduationChart.setOption(option);
      $(window).resize(() => {
        this.graduationChart.resize();
      });
    },
    drawIndustry() {
      let option;
      let color = ["rgba(113, 164, 161, 0.8)", "#61BFB4", "#EDCA75"];
      option = {
        grid: {
          top: 30,
          bottom: 0,
          left: 10,
          right: 10,
          containLabel: false,
        },
        tooltip: {
          show: true,
          trigger: "item",
        },
        xAxis: {
          show: false,
          type: "category",
          data: this.industryData.xdata,
          axisLabel: {
            color: "#B9B8CE",
            fontSize: this.utils.transferPx(9),
          },
          axisLine: { lineStyle: { color: "#B9B8CE" } },
        },
        yAxis: {
          show: false,
          type: "value",
        },
        series: [
          {
            data: this.industryData.data,
            type: "bar",
            color: color,
            colorBy: "data",
            barMaxWidth: 80,
            label: {
              show: true,
              position: "top",
              fontSize: this.utils.transferPx(14),
              formatter: "{b}",
              width: 120,
              overflow: "truncate",
            },
          },
        ],
      };
      let chartDom = document.getElementById("industry");
      if (this.industryChart && this.industryChart.id) {
        this.industryChart.clear();
      }
      this.industryChart = echarts.init(chartDom);
      option && this.industryChart.setOption(option);
      $(window).resize(() => {
        this.industryChart.resize();
      });
    },
    drawAge() {
      let option;
      let color = ["rgba(113, 164, 161, 0.8)", "#61BFB4", "#EDCA75"];
      option = {
        grid: {
          top: 30,
          bottom: 0,
          left: 10,
          right: 10,
          containLabel: true,
        },
        tooltip: {
          show: true,
          trigger: "item",
        },
        xAxis: {
          show: false,
          type: "value",
          axisLabel: {
            color: "#B9B8CE",
            fontSize: this.utils.transferPx(10),
          },
          axisLine: { lineStyle: { color: "#B9B8CE" } },
        },
        yAxis: {
          show: true,
          type: "category",
          data: this.ageData.xdata,
          axisLabel: {
            color: "#000",
            fontSize: this.utils.transferPx(14),
          },
          axisLine: { show: false, lineStyle: { color: "#B9B8CE" } },
          axisTick: { show: false },
        },
        series: [
          {
            data: this.ageData.data,
            type: "bar",
            color: color,
            colorBy: "data",
            barWidth: 30,
            label: {
              show: false,
              position: "top",
              fontSize: this.utils.transferPx(14),
              formatter: "{b}",
            },
          },
        ],
      };
      let chartDom = document.getElementById("age");
      if (this.ageChart && this.ageChart.id) {
        this.ageChart.clear();
      }
      this.ageChart = echarts.init(chartDom);
      option && this.ageChart.setOption(option);
      $(window).resize(() => {
        this.ageChart.resize();
      });
    },
    drawScore() {
      let option;
      option = {
        color: ["#65C0B4", "#91CC75", "#FAC858", "#FF8A8A", "#93C1CB"],
        legend: {
          bottom: 0,
          left: "center",
          // doesn't perfectly work with our tricks, disable it
          selectedMode: false,
          itemGap: 10,
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}\n{c}%",
        },
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          containLabel: true,
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["65%", "120%"],
            center: ["50%", "80%"],
            // adjust the start angle
            startAngle: 180,
            label: {
              show: true,
              position: "outside",
              formatter: "{b}\n{fb|{c}%}",
              lineHeight: 18,
              fontSize: this.utils.transferPx(12),
              rich: {
                fb: {
                  fontWeight: "bold",
                  fontSize: this.utils.transferPx(12),
                },
              },
            },
            labelLine: {
              show: true,
              showAbove: true,
              length: 15,
              length2: 10,
            },
            labelLayout: (params) => {
              const isLeft =
                params.labelRect.x < this.scoreChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points,
              };
            },
            data: [
              ...this.scoreData,
              {
                // make an record to fill the bottom 50%
                name: "",
                value: this.emptyData,
                itemStyle: {
                  // stop the chart from rendering this piece
                  color: "none",
                  decal: {
                    symbol: "none",
                  },
                },
                label: {
                  show: true,
                  formatter: "",
                },
              },
            ],
          },
        ],
      };
      let chartDom = document.getElementById("score");
      if (this.scoreChart && this.scoreChart.id) {
        this.scoreChart.clear();
      }
      this.scoreChart = echarts.init(chartDom);
      option && this.scoreChart.setOption(option);
      $(window).resize(() => {
        this.scoreChart.resize();
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
.edp-crowdChart {
  width: 100%;
  padding: toPad(10) toPad(40) toPad(34);
  .chart-block {
    height: toPad(360);
    margin-bottom: toPad(20);
    &:last-of-type {
      height: toPad(300);
    }
    .chart-item {
      height: 100%;
      padding: toPad(20);
      background: #ffffff;
      box-shadow: 0px 4px 13px rgba(230, 236, 244, 0.6);
      border-radius: 10px;
      //border: 1px solid #000;
      .tips {
        display: inline-block;
        width: toPad(15);
        height: toPad(15);
        margin-left: toPad(8);
        cursor: pointer;
      }
      .list-empty {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
      }
      .chart-wrap {
        width: 100%;
        height: 95%;
      }
      &.one {
        display: flex;
        width: 57%;
        .num-block {
          position: relative;
          width: 32%;
          padding: 0;
          background: transparent;
          &::after {
            position: absolute;
            right: toPad(20);
            top: toPad(30);
            content: "";
            width: 0;
            height: toPad(254);
            border-left: 1px solid rgba(0, 0, 0, 0.1);
          }
          .num-item {
            height: toPad(100);
            &.avgs {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .tit {
                align-self: flex-start;
              }
              .avg-block {
                width: 100%;
                .avg-line {
                  width: 1px;
                  height: toPad(71);
                  background: rgba(0, 0, 0, 0.05);
                }
                .desc {
                  color: rgba(0, 0, 0, 0.5);
                }
              }
            }
            .indu-empty {
              margin: 0;
            }
          }
        }
        .age-wrap {
          width: 68%;
          height: 100%;
          .chart-wrap {
            width: 100%;
            height: 95%;
          }
        }
      }
      &.two {
        width: 42%;
      }
      &.thr {
        width: 33.67%;
      }
      &.four {
        width: 33.67%;
      }
      &.five {
        width: 30.71%;
      }
      &.six {
        width: 33.67%;
      }
      &.seven {
        display: flex;
        width: 65.4%;
        .score-wrap {
          position: relative;
          width: 64%;
          height: 100%;
          &::after {
            position: absolute;
            right: toPad(-10);
            top: toPad(36);
            content: "";
            width: 0;
            height: toPad(254);
            border-left: 1px solid rgba(0, 0, 0, 0.1);
          }
          .chart-wrap {
            width: 100%;
            height: 95%;
          }
        }
        .perfor-wrap {
          width: 36%;
          .number-chart {
            position: relative;
            width: 80%;
            height: toPad(180);
            margin: toPad(10) auto;
            .num-above {
              z-index: 3;
              position: absolute;
              width: toPad(110);
              height: toPad(110);
              line-height: toPad(110);
              border-radius: 50%;
              background: #61bfb4;
            }
            .num-below {
              position: relative;
              top: toPad(20);
              left: toPad(30);
              width: toPad(85);
              height: toPad(85);
              border-radius: 50%;
              background: #ffd36c;
            }
          }
        }
      }
      &.num-block {
        padding: 0;
        background: transparent;
        .num-item {
          height: toPad(170);
          padding: toPad(20);
          background: #ffffff;
          box-shadow: 0px 4px 13px rgba(230, 236, 244, 0.6);
          border-radius: 10px;
          &.avgs {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .tit {
              align-self: flex-start;
            }
            .avg-block {
              width: 100%;
              .avg-line {
                width: 1px;
                height: toPad(71);
                background: rgba(0, 0, 0, 0.05);
              }
              .desc {
                color: rgba(0, 0, 0, 0.5);
              }
            }
          }
          .indu-empty {
            margin: 0;
          }
        }
      }

      & .ind-wrap {
        width: 90%;
        padding: toPad(20) 0;
        .ind-item {
          margin-bottom: toPad(14);
          span {
            display: inline-block;
            width: toPad(48);
            height: toPad(18);
            line-height: toPad(18);
            margin-right: toPad(10);
            border-radius: toPad(20);
            background: #61bfb4;
            text-align: center;
          }

          &:nth-of-type(2) {
            span {
              background: #edca75;
            }
          }
          &:nth-of-type(3) {
            span {
              background: #8eb6b3;
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.el-tooltip__popper[x-placement^="left"] .popper__arrow::after {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}

.el-tooltip__popper[x-placement^="left"] .popper__arrow {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}
.el-tooltip__popper[x-placement^="right"] .popper__arrow::after {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}

.el-tooltip__popper[x-placement^="right"] .popper__arrow {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}
.draw_share_atooltip {
  background: #dff2f0 !important;
  color: #000 !important;
}
</style>
